.app {
  /* background-color: #ff7b29; */
  width: 80%;
  margin-left: 10%;
}

/* min size style*/
@media screen and (max-width:500px) {
  /* put your css style in there */
  .app {
    /* background-color: #ff7b29; */
    width: 100%;
    margin-left: 0%;
    padding-left: 10px;
    padding-right: 10px;
  }
}
