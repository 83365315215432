.header {
  height: 170px;
  display: flex;
  background-image: url('https://media.licdn.com/dms/image/v2/D5612AQH3GJzku56a5w/article-cover_image-shrink_600_2000/article-cover_image-shrink_600_2000/0/1674670868282?e=2147483647&v=beta&t=vsygIE1CAw0TlNTE815T6kiOtQoUjjMMREzJopypiPg');
  background-repeat: no-repeat;
  /* width: 80%;   */
  background-size: cover;
  /* margin-left: 10%; */
  justify-content: center;
}
.image {
  height: 170px;
  padding: 15px;
}
.text_h1 {
  font-size: xx-large;
  font-weight: bold;
  color: white;
}
.text_h3 {
  font-size: x-large;
  font-weight: bold;
  color: white;
  text-align: center;
}
.text_h6 {
  font-size: large;
  font-weight: bold;
  color: white;
  text-align: center;
}
.text_container {
  display: grid;
  align-content: center;
}