.navbar {
    background-color: #ff7b29;
}

.button {
    width: 100%;
    background-color: transparent !important;
    color: white !important;
}

.button:hover {
    background-color: #f7670d !important;
}
.w100 {
    width: 100%;
}